.item-input-box {
    padding-top: 150px;
}

.item-input-box table {
    width: 96%;
    margin-left: 2%;
    border-collapse: collapse;
    position: relative;
    text-align: left;
    padding-bottom: 100px;
}

.item-input-box tr:nth-child(odd) {
    border: none;
    width: 50px;
    background: rgba(200, 200, 200, 0.15);
    padding: 10px;
}

.crud-item-input-box tr {
    width: 100%;
}

.crud-item-input-box tr td:first-child {
    width: 22%;
    text-align: left;
    font-size: 1.2em;
    padding-left: 25px;
    vertical-align: top;
    padding-top: 25px;
}

.crud-item-input-box tr td:nth-child(2) {
    text-align: left;
}

.crud-model-table td:nth-child(2) {
    padding-top: 20px;
}

.crud-item-input-box {
    padding-top: 150px;
}

.crud-item-input-box table {
    width: 96%;
    margin-left: 2%;
    border-collapse: collapse;
    position: relative;
    text-align: left;
    padding-bottom: 100px;
}


.crud-item-input-box tr:nth-child(odd) {
    border: none;
    width: 50px;
    background: rgba(200, 200, 200, 0.15);
    padding: 10px;
}


td {
    border: none;
    padding: 10px;
}

.alert-label {
    color: red;
}

.item-input-box td:first-child {
    font-size: 1.1em;
    padding-left: 20px;
    width: 20%;
}

.item-input-box td:last-child {
    padding-right: 20px;
    text-align: right;
}


.crud-item-input-box input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
select,
textarea {
    margin-top: 15px;
    margin-bottom: 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: 4px;
    border: solid 1px rgba(16, 38, 66, 0.3);
    display: block;
    outline: 0;
    padding: 0 0 0 5px;
    font-size: 1em;
    /*width: 47%;*/
    background: rgba(255, 255, 255, 0.6);
    height: 30px;
}

.crud-item-input-box input:disabled,
input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="email"]:disabled,
input[type="tel"]:disabled,
input[type="number"]:disabled,
select:disabled,
textarea:disabled {
    margin-top: 15px;
    margin-bottom: 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: 4px;
    border: solid 1px rgba(16, 38, 66, 0.1);
    display: block;
    outline: 0;
    padding: 0 0 0 5px;
    font-size: 1em;
    width: 96%;
    background: rgba(255, 255, 255, 0.3);
    height: 30px;
    color: rgba(50, 50, 53, 0.7);
}

input:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus {
    border: solid 1px rgba(75, 75, 93, 0.45);
    box-shadow: 0 0 0 1px rgba(243, 140, 0, 0.4);
    background: rgba(255, 255, 255, 0.4);
}

select {
    border-color: rgba(75, 75, 93, 0.45);
    padding-left: 5px;
    width: 47.5%;
    background: rgba(255, 255, 255, 0.6) url('../../common/img/down_arrow_opacity.png') no-repeat 95%;
    background-size: 20px;
    height: 34px;
}

select:focus {

    background: rgba(255, 255, 255, 0.6) url('../../common/img/down_arrow_opacity.png') no-repeat 95%;
    background-size: 20px;
    height: 34px;
}

.crud-item-input-box select {
    color: rgba(50, 50, 53, 0.9);
    margin-top: 15px;
    margin-bottom: 15px;
    border: solid 1px rgba(16, 38, 66, 0.3);
    padding-left: 5px;
    width: 96%;
    background: rgba(255, 255, 255, 0.3) url('../../common/img/down_arrow_opacity.png') no-repeat 95%;
    background-size: 20px;
    height: 34px;
}

.crud-item-input-box select:focus {
    box-shadow: 0 0 0 1px rgba(243, 140, 0, 0.4);
    background: rgba(255, 255, 255, 0.4);
    color: rgba(50, 50, 53, 0.9);
    margin-top: 15px;
    margin-bottom: 15px;
    border: solid 1px rgba(75, 75, 93, 0.45);
    padding-left: 5px;
    width: 96%;
    background: rgba(255, 255, 255, 0.4) url('../../common/img/down_arrow_opacity.png') no-repeat 95%;
    background-size: 20px;
    height: 34px;
}

.crud-item-input-box select:disabled {
    color: rgba(50, 50, 53, 0.5);
    margin-top: 15px;
    margin-bottom: 15px;
    border: solid 1px rgba(16, 38, 66, 0.1);
    padding-left: 5px;
    width: 47.5%;
    background: rgba(255, 255, 255, 0.3) url('../../common/img/down_arrow_opacity.png') no-repeat 95%;
    background-size: 20px;
    height: 34px;
}


.input-box input {
    margin-top: 15px;
    margin-bottom: 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: 3px;
    border: solid 2px rgba(16, 38, 66, 0.3);
    display: block;
    outline: 0;
    padding: 0 0 0 5px;
    font-size: 1em;
    /*width: 47%;*/
    background: rgba(255, 255, 255, 0.6);
    height: 30px;
}

.input-box input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
select,
textarea {
    margin-top: 15px;
    margin-bottom: 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: 3px;
    border: solid 2px rgba(16, 38, 66, 0.3);
    display: block;
    outline: 0;
    padding: 0 0 0 5px;
    font-size: 1em;
    /*width: 47%;*/
    background: rgba(255, 255, 255, 0.6);
    height: 30px;
}

.input-box input:disabled,
input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="email"]:disabled,
input[type="tel"]:disabled,
input[type="number"]:disabled,
select:disabled,
textarea:disabled {
    margin-top: 15px;
    margin-bottom: 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: 3px;
    border: solid 2px rgba(16, 38, 66, 0.1);
    display: block;
    outline: 0;
    padding: 0 0 0 5px;
    font-size: 1em;
    width: 47%;
    background: rgba(255, 255, 255, 0.3);
    height: 30px;
    color: rgba(50, 50, 53, 0.7);
}

.items-container td {
    padding-left: 7px;
}
.items-container tr {
    padding: 4px;
}

.file {
    cursor: pointer;
    display: inline-block;
    /*font-size: 13.298px;*/
    /*font-size: .7696rem;*/
    max-width: 100%;
    margin-top: 10px;
    position: relative;
    margin-bottom: 25px;
}

.file-input {
    filter: alpha(opacity=0);
    margin: 0;
    max-width: 100%;
    min-width: 450px;
    opacity: 0
}

.file-control {
    text-align: left;
    border-radius: 4px;
    background-color: #fff;
    border: 2px solid rgba(75, 75, 93, 0.45);
    /*color: rgba(75, 75, 93, 0.95);*/
    font-weight: 400;
    height: 22px;
    left: 0;
    /*line-height: 10px;*/
    padding: 5px 5px;
    /*padding: .9722rem 1.2731rem;*/
    position: absolute;
    right: 0;
    top: 1px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
    transition: background-color .15s ease-out
}

.file-control:after {
    content: "C";
    opacity: 0;
}

.file-control:before {
    background-color: rgba(39, 52, 139, 0.65);
    border: rgba(39, 52, 139, 0.65);
    border-radius: 4px;
    bottom: -1px;
    color: #fff;
    content: "BROWSE";
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    /*height: 53px;*/
    letter-spacing: .05em;
    /*line-height: 1.25;*/
    padding: 8px 20px;
    /*padding: .9722rem 1.2731rem;*/
    position: absolute;
    right: -1px;
    top: -1px;
    z-index: 2;
    transition: background-color .15s ease-out
}

.file-control:hover:before {
    background-color: rgba(75, 75, 93, 0.65);
    border-color: transparent
}

.form-control-label {
    margin-bottom: 0;
    /*padding: 16.799px 21.999px;*/
    /*padding: .9722rem 1.2731rem*/
}


.image-preview-container {
    padding: 5px;
    display: none;
    border: 1px solid;
    position: absolute;
    top: 140px;
    right: 100px;
    width: 220px;
    height: 240px;
    background: white;
    opacity: 1;
    box-shadow: 0 3px 8px 3px #888888;
    z-index: 50;
}

.model {
    width: 280px;
    height: 300px;
    position: fixed;
}


.image-preview-header {
    height: 7%;
    padding-left: 1%;
    padding-right: 1%;
    border-bottom: 1px solid rgba(75, 75, 93, 0.45);

}

.image-preview-image-holder {
    /*height: 70%;*/
    padding: 1%;


}

.image-holder {
    width: 45%;
    float: left;
    /*margin-left: 5px;*/
}

.crud-model-input-box {
    padding-top: 150px;
}

.crud-model-input-box table {
    position: relative;
    text-align: left;
    padding-bottom: 100px;
}

.crud-model-input-box td:first-child {
    padding-right: 50px;
    width: 20%;
}

.crud-model-input-box td:last-child:hover {
    text-decoration: underline;
    cursor: pointer;
}

fieldset {
    margin-top: 10px;
    margin-bottom: 10px;
    border-color: rgba(30, 30, 33, 0.4);
    border-radius: 4px;
}

.custom-radiobutton {
    display: block;
    margin: 10px 0;
    position: relative;
    width: 45%;
}


.custom-checkbox {
    display: block;
    margin: 10px 0;
    position: relative;
    width: 45%;
}

/*.crud-model-input-box input[type="text"],*/
/*input[type="password"],*/
/*input[type="email"],*/
/*input[type="tel"],*/
/*input[type="number"],*/
/*select,*/
/*textarea {*/
/*    margin-top: 10px;*/
/*    margin-bottom: 10px;*/
/*    -moz-appearance: none;*/
/*    -webkit-appearance: none;*/
/*    -ms-appearance: none;*/
/*    appearance: none;*/
/*    border-radius: 4px;*/
/*    border: solid 2px rgba(16, 38, 66, 0.3);*/
/*    display: block;*/
/*    outline: 0;*/
/*    padding: 0 0 0 5px;*/
/*    font-size: 1em;*/
/*    width: 47%;*/
/*    background: rgba(255, 255, 255, 0.6);*/
/*    height: 30px;*/
/*}*/

/*.crud-model-input-box input[type="text"]:focus,*/
/*input[type="password"]:focus,*/
/*input[type="email"]:focus,*/
/*input[type="tel"]:focus,*/
/*input[type="number"]:focus,*/
/*select:focus,*/
/*textarea:focus {*/
/*    border: solid 2px rgba(75, 75, 93, 0.6);*/
/*    box-shadow: 0 0 0 1px rgba(243, 140, 0, 0.4);*/
/*    background: rgba(255, 255, 255, 0.4);*/
/*}*/

/*.crud-model-input-box select {*/
/*    border-color: rgba(30, 30, 30, 0.4);*/

/*    width: 47%;*/
/*    background: rgba(255, 255, 255, 0.6) url('../img/down_arrow_opacity.png') no-repeat 95%;*/
/*    background-size: 20px;*/
/*    height: 32px;*/
/*}*/

/*.crud-model-input-box select:focus {*/
/*    width: 47%;*/
/*    background: rgba(255, 255, 255, 0.6) url('../img/down_arrow_opacity.png') no-repeat 95%;*/
/*    background-size: 20px;*/
/*    height: 32px;*/
/*}*/


.upload-button {
    border-radius: 28px;
    position: absolute;
    left: 21%;
    top: 2%;
    display: block;
    background: #fff;
    width: 150px;
    box-shadow: 0 2px 6px rgba(120, 135, 150, 0.4);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.upload-button svg {
    display: none;
    position: absolute;
    left: 40%;
    top: 50%;
    margin: -15px 0 0 -15px;
    fill: #fff;
}

.upload-button div {
    height: 4px;
    margin: -2px 0 0 0;
    position: absolute;
    top: 50%;
    left: 71px;
    right: 25px;
    background: #d3d7e0;
    display: none;
    border-radius: 2px;
}

.upload-button div span {
    position: absolute;
    background: rgba(255, 150, 45, 1);
    height: 4px;
    left: 0;
    top: 0;
    width: 0;
    display: block;
    border-radius: 2px;
}

.upload-button a {
    cursor: pointer;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: 2px;
    font-style: italic;


    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    position: relative;
    display: block;
    background: rgba(75, 75, 93, 0.9);
    z-index: 2;
    line-height: 46px;
    height: 46px;
    border-radius: 28px;
    width: 100%;
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
    color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 6px rgba(120, 135, 150, 0.4);
}

.upload-button a span {
    cursor: pointer;
    display: block;
}

.upload-button a:hover {

    background: rgba(115, 115, 133, 0.95);

}

#image_preview {
    width: 100%;
}

#item_save_button_container {
    text-align: right;
    width: 58%;
    position: relative;
    top: -63px;
}


@media screen and (max-width: 760px) {


}

@media screen and (max-width: 1020px) {


}

@media screen and (max-width: 1280px) {

    #item_save_button_container {
        text-align: right;
        width: 70%;
        position: relative;
        top: -63px;
    }

    .image-preview-container {
        padding: 5px;
        display: none;
        border: 1px solid;
        position: absolute;
        top: 140px;
        right: 35px;
        width: 200px;
        height: 220px;
        background: white;
        opacity: 1;
        box-shadow: 0 3px 8px 3px #888888;
        z-index: 50;
    }


    .crud-item-input-box input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    select,
    textarea {
        margin-top: 15px;
        margin-bottom: 15px;
        -moz-appearance: none;
        -webkit-appearance: none;
        -ms-appearance: none;
        appearance: none;
        border-radius: 4px;
        border: solid 2px rgba(16, 38, 66, 0.3);
        display: block;
        outline: 0;
        padding: 0 0 0 5px;
        font-size: 1em;
        /*width: 40%;*/
        background: rgba(255, 255, 255, 0.6);
        height: 30px;
    }

}

@media screen and (max-width: 1680px) {


}

@media screen and (max-width: 3000px) {


}
