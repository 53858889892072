body, input, select, textarea {
    color: hsl(0, 0%, 21%);
}

.header {
    background: rgba(255, 255, 255, 0.95);
}

td {
    color: hsl(0, 0%, 30%)
}

.logo {
    height: 45px;
    content: url('../../common/img/images-schein/schein-logo.png');
    padding: 10px;
    margin-left: 5px;
}

.header-icon {
    color: rgba(55, 55, 55, 0.9);
}


.path-background {
    background: transparent;
}

.path {
    background: rgba(246, 140, 35, 0.8);
}

.sub-bar {
    background: rgba(225, 226, 241, 0.7);
    color: rgba(55, 55, 55, 0.9);
}

.backend-item-header {
    /*background: rgba(53,53,53, 0.5);*/
    color: rgba(255, 255, 255, 0.9);
    background: rgba(171, 186, 195, 1);
}

th {
    /*background: rgba(225, 226, 241, 1);*/
    /*background: rgba(53,53,53, 0.55);*/
    /*background: rgba(246, 140, 35, 0.7);*/
    background: rgba(171, 186, 195, 1);
    color: rgba(255, 255, 255, 0.95);
    /*color: rgba(39,52,139, 1);*/
    /*color: rgba(39,52,139, 1);*/
    border: none;
}
