
body, input, select, textarea {
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    color: rgba(90, 90, 93, 0.95);
    margin: 0;
    background: rgba(255, 255, 255, 0.9);
    font-size: 100%;

}

.publish_database {
    display: none;
}

.alert-circle {
    cursor: pointer;
    text-align: center;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background: rgba(255, 150, 45, 0.95);
    color: white;
    display: block;
}

.alert-circle i {
    position: absolute;
    margin: 0;
    left: 8px;
    top: 4px;
    font-size: 10px;
}

@keyframes rotating {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.rotate {
    animation: rotating 0.5s linear infinite;
}

.index-number {
    display: none;
}

/*GLOBAL HEADER STYLES*/
.header {
    top: 0;
    left: 0;
    background: rgba(75, 75, 93, 0.95);
    position: absolute;
    width: 100%;
    height: 70px;
}

.header-left {
    float: left;
    width: 50%;
}

.header-right {
    float: right;
    width: 40%;
    padding-right: 10px;
}

.publish_database {
    cursor: pointer;
    /*display: none;*/
}

.logo {
    height: 40px;
    content: url('../../common/img/images-fittr/fittr_logo_white.png');
    padding: 15px;
    margin-left: 15px;
}

.header-items-box {
    list-style: none;
    padding: 0;
    margin: 0;
}

.header-icon {
    position: relative;
    float: right;
    color: rgba(255, 255, 255, 0.9);
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 2em;
    line-height: 70px;
}


.path-background {
    left: 0;
    background: rgba(85, 85, 103, 0.5);
    position: absolute;
    top: 70px;
    height: 20px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    color: rgba(255, 255, 255, 0.95);
}

.path {
    left: 0;
    background-color: rgba(255, 150, 45, 0.5);
    position: absolute;
    top: 70px;
    height: 20px;
    width: 98%;
    padding-left: 2%;
    padding-top: 10px;
    padding-bottom: 10px;
    color: rgba(255, 255, 255, 0.9);
}

.path a {
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    text-decoration: none;
}

.path a:hover {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: underline;
    cursor: pointer;
}

.sub-bar {
    width: 97%;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 1%;
    height: 55px;
    top: 130px;
    left: 50%;
    background: rgba(75, 75, 93, 0.15);
    position: absolute;
    margin-left: -49%;
}

/*GLOBAL ITEM LISTS STYLES*/
.link {
    cursor: pointer;
}

.link:hover {
    cursor: pointer;
    text-decoration: underline;
    opacity: 0.8;
}

.thumbnails {
    padding: 10px;
    height: 140px;
}

.items-container {
    /*position: absolute;*/
    width: 98%;
    margin-left: 1%;
    top: 210px;
}

.items-container-inner {

}

.items-container-inner table {
    width: 100%;
    margin-left: 0;
    border-collapse: collapse;
}

th {
    padding: 10px;
    background: rgba(75, 75, 93, 0.4);
    color: rgba(255, 255, 255, 0.95);
    border: none;
}

.items-container-inner td:last-child i {
    margin-right: 5px;
}

.items-container-inner td:last-child:hover {
    text-decoration: underline;
    cursor: pointer;
}

tbody {
    border: none;
}

thead {
    border: none;
}

td {
    border: none;
}

tr a {
    color: rgba(39, 52, 139, 0.85);
    margin-right: 20px;
}

.list-item:hover {
    cursor: pointer;
    text-decoration: underline;
}

.items-container-inner tr:nth-child(odd) {
    border: none;
    width: 50px;
    background: rgba(200, 200, 200, 0.15);
    padding: 10px;
}

.items-container-inner td {
    padding: 10px 15px;
}


/*th {*/
/*    padding: 10px;*/
/*    background: rgba(53,53,53, 0.5);*/
/*    color: rgba(255, 255, 255, 0.95);*/
/*    border: none;*/
/*}*/

.items-container-inner th div {
    display: inline-block;
    cursor: pointer;
}


/*GLOBAL INPUT FIELDS STYLES*/
input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
select,
textarea {
    padding-left: 1%;
    padding-right: 2%;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: solid 1px rgba(53, 53, 53, 0.5);
    display: block;
    outline: 0;
    /*width: 43%;*/
    background: rgba(255, 255, 255, 0.6);
    height: 28px;
}

input:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus {
    border: solid 1px rgba(53, 53, 53, 0.5);
    box-shadow: 0 0 0 1px rgba(39, 52, 139, 0.65);
    background: rgba(255, 255, 255, 0.4);
}

label {
    font-weight: 700;
    opacity: 0.9;
    font-size: 16px;
}

td input {
    padding-left: unset;
    padding-right: unset;
    margin-top: unset;
    margin-bottom: unset;
    border-radius: unset;
    border: unset;
    display: block;
    outline: unset;
    /*width: 43%;*/
    background: rgba(255, 255, 255, 0.6);
    height: unset;
}
td input:focus {
    border: unset;
    box-shadow: unset;
    background: unset;
}

/*GLOBAL BUTTONS STYLES*/
.button-container {
    padding-top: 30px;
    margin-bottom: 50px;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    background: rgba(75, 75, 93, 0.95);
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 0 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    margin-top: 10px;
    border: none;
    font-size: 16px;
    font-style: italic;
    line-height: 35px;
    /*height: 35px;*/
    border-radius: 3px;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover,
.button:hover {
    background: rgba(39, 52, 139, 0.75);
    color: rgba(255, 255, 255, 0.95);
}

input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
button:active,
.button:active {
    background: rgba(39, 52, 139, 0.75);
    color: rgba(255, 255, 255, 0.95);
}

.delete {
    color: rgba(255, 255, 255, 0.9);
    float: right;
    background: rgba(255, 10, 10, 0.7);
    border: none;
    border-radius: 2px;
    margin-top: 5px;
    margin-right: 35px;
    line-height: 45px;
}

.delete:hover {
    text-decoration: none;
    background: rgba(255, 10, 10, 0.4);
}

.add-item-button {
    color: rgba(255, 255, 255, 0.9);
    float: right;
    border-radius: 2px;
    background: rgba(39, 52, 139, 0.65);
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 45px;
    margin-top: 5px;
    margin-right: 10px;
}

.add-item-button:hover {
    text-decoration: none;
    background: rgba(39, 52, 139, 0.35);
}


.category-icon-container {
    position: relative;
    float: left;
    height: 100%;
}

.category-icon-shadow {
    background: transparent;
    left: 0;
    top: 0;
    position: absolute;
    height: 65px;
    width: 100%;
    opacity: 1;
    border: none;
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.85);
}

.category-icon {
    background: rgba(53, 53, 53, 0.7);
    position: relative;
    border: none;
    top: 0;
    left: 0;
    height: 65px;
    width: 65px;
    /*display: inline;*/
    border-radius: 3px;
    margin-left: 0;
    margin-right: 0;
    margin-top: -5px;
    margin-bottom: -5px;
    color: rgba(255, 255, 255, 0.95) !important;
    font-size: 0.9em;
    /*color: rgba(39,52,139, 0.55)!important;*/
    /*opacity: 0.4;*/
}

.category-icon:hover {
    background-color: rgba(0, 81, 139, 0.4);
    /*background: rgba(53,53,53, 0.7);*/
    /*opacity: 0.65;*/
}

.category-active {
    background-color: rgba(0, 81, 139, 0.4);
    /*background: rgba(53,53,53, 0.7);*/
    /*opacity: 0.85;*/
    color: rgba(39, 52, 139, 0.95) !important;
    /*color: rgba(255, 255, 255, 0.85)!important;*/

}

.category-icon-active {
    /*color: rgba(255, 255, 255, 0.9) !important;*/
    color: rgba(39, 52, 139, 0.95) !important;
    /*color: rgba(255, 255, 255, 0.85)!important*/
}

.category-active:hover {
    /*background: rgba(53,53,53, 0.7);*/
    /*color: rgba(255, 255, 255, 0.8)!important;*/
    /*opacity: 0.95;*/
    /*color: rgba(255, 150, 45, 1) !important*/
}

/*.category-icon:hover i{*/
/*    color: rgba(255, 150, 45, 1)!important;*/
/*}*/
.category-icon i {
    position: relative;
    padding-top: 8px;
    padding-left: 2px;
    font-size: 1.8em;
    /*color: rgba(255, 150, 45, 1);*/
    color: rgba(255, 255, 255, 0.9);
    /*color: rgba(39,52,139, 0.55);*/
    opacity: 1;
}

/*GLOBAL SEARCH INPUT STYLES*/


input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: .25rem;
    border: 1px solid #ced4da;
    display: block;
    outline: 0;
    padding: 0 0;
    margin: 12px;
    font-size: 1em;
    width: 99%;
    background: #fff;
    height: 30px;
    color: #495057;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

input[type="search"]:focus {
    border: solid 1px rgba(53, 53, 53, 0.5);
    /*box-shadow: 0 0 0 1px rgba(16, 38, 66, 0.5);*/
    background: rgba(255, 255, 255, 0.4);
}


.search-box {
    /*position: absolute;*/
    left: 30%;
    float: left;
}

.search-box input {
    width: 200px;
    float: left;
}

/*.search-box i {*/
/*    color: rgba(30, 30, 30, 0.5);*/
/*    line-height: 28px;*/
/*    margin-top: 13px;*/
/*    padding-top: 0;*/
/*    padding-bottom: 0;*/
/*    !*padding-top: 6px;*!*/
/*    !*padding-right: 5px;*!*/
/*    !*font-size: 1.5em;*!*/
/*    !*font-weight: 500;*!*/
/*    !*float: left;*!*/
/*}*/

.search-input {
    box-sizing: border-box;
    height: 28px;
    width: 28px;
    border: 2px solid rgba(255, 255, 255, 0.85);
    border-radius: 50%;
    background: none;
    /*color: rgba(0, 81, 139, 1);*/
    color: rgba(255, 255, 255, 0.85);
    /* margin-right: 30px; */
    /* margin-top: -47px; */
    font-weight: 400;
    outline: 0;
    -webkit-transition: width 0.4s ease-in-out, border-radius 0.8s ease-in-out, padding 0.2s;
    transition: width 0.4s ease-in-out, border-radius 0.8s ease-in-out, padding 0.2s;
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
    /* -webkit-transform: translate(-100%, -50%); */
    /* -ms-transform: translate(-100%, -50%); */
    /* transform: translate(-100%, -50%); */
    position: relative;
    top: 0;
    left: -1px;
}

.search {
    background: none;
    position: absolute;
    top: 8px;
    right: 3px;
    height: 2.5rem;
    width: 3em;
    padding: 0;
    border-radius: 100%;
    outline: 0;
    border: 0;
    /*color: rgba(0, 81, 139, 1);*/
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    -webkit-transform: translate(10%, 10%);
    -ms-transform: translate(10%, 10%);
    transform: translate(10%, 10%);
}

.search:hover {
    background: none;
}

.search:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 3px;
    /*background: rgba(0, 81, 139, 1);*/
    background: rgba(255, 255, 255, 0.85);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: 20px;
    margin-left: 13px;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}


.search-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(53, 53, 53, 0.7);
    border-radius: 5px;
    border: 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 2px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    top: 18px;
    position: absolute;
    /* left: 36%; */
    height: 28px;
    padding: 0 1em;
    display: inline;
    /*position: relative;*/
}

.search-button:hover {
    background-color: rgba(0, 81, 139, 0.4);
}

.search-button:active {
    background-color: rgba(0, 81, 139, 0.4);
}

/*GLOBAL CUSTOM CHECKBOX AND RADIOBUTTON STYLES*/

fieldset {
    margin-top: 10px;
    margin-bottom: 10px;
    border-color: rgba(30, 30, 33, 0.4);
    border-radius: 4px;
    border-width: 1px;
}

.custom-radiobutton {
    display: block;
    /*margin: 10px 10px;*/
    position: relative;
    width: 92%;
}

.custom-radiobutton label {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 98%;
    padding-left: 2%;
    display: block;
    text-align: left;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 100ms ease-in;
    -webkit-transition: color 100ms ease-in;
    overflow: hidden;
    margin: 10px 0;
    background-color: rgba(75, 75, 93, 0.08);
}

.custom-radiobutton label:before {

    height: 10px;
    border-radius: 50%;
    content: '';
    background-color: rgba(75, 75, 93, 0.3);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: -1;
}

.custom-radiobutton label:after {
    width: 22px;
    height: 22px;
    content: '';
    border: 2px solid rgba(75, 75, 93, 0.9);
    /*border: 2 px solid rgba(255, 150, 45, 0.9);*/
    background-color: transparent;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    right: 10%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 100ms ease-in;
    -webkit-transition: all 100ms ease-in;
}


.custom-radiobutton input:checked ~ label {
    outline: 0;
    color: rgba(255, 255, 255, 0.95);
}

.custom-radiobutton input:checked ~ label:before {
    -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
    transform: translate(-50%, -50%) scale3d(56, 56, 1);
    opacity: 1;
}

.custom-radiobutton input:checked ~ label:after {
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: rgba(255, 150, 45, 0.9);
    border-color: rgba(75, 75, 93, 0.9);
}

.custom-radiobutton input {
    width: 22px;
    height: 22px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
}


.custom-checkbox {
    display: block;
    margin: 10px 10px;
    position: relative;
    width: 95%;
}

.custom-checkbox label {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 98%;
    padding-left: 2%;
    display: block;
    text-align: left;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 100ms ease-in;
    -webkit-transition: color 100ms ease-in;
    overflow: hidden;
    margin: 10px 0;
    background-color: rgba(75, 75, 93, 0.08);
}

.custom-checkbox label:before {

    height: 10px;
    border-radius: 5%;
    content: '';
    background-color: rgba(75, 75, 93, 0.3);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: -1;
}

.custom-checkbox label:after {
    width: 22px;
    height: 22px;
    content: '';
    border: 2px solid rgba(75, 75, 93, 0.9);
    /*border: 2 px solid rgba(255, 150, 45, 0.9);*/
    background-color: transparent;
    border-radius: 5%;
    z-index: 2;
    position: absolute;
    right: 10%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 100ms ease-in;
    -webkit-transition: all 100ms ease-in;
}


.custom-checkbox input:checked ~ label {
    outline: 0;
    color: rgba(255, 255, 255, 0.95);
}

.custom-checkbox input:checked ~ label:before {
    -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
    transform: translate(-50%, -50%) scale3d(56, 56, 1);
    opacity: 1;
}

.custom-checkbox input:checked ~ label:after {
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: rgba(255, 150, 45, 0.9);
    border-color: rgba(75, 75, 93, 0.9);
}

.custom-checkbox input {
    width: 22px;
    height: 22px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
}

.wrapper {
    width: 98%;
    position: absolute;
    top: 215px;
    left: 0;
}

.tabs {
    position: relative;
    /*margin: 3rem 0;*/
    background: rgba(255, 255, 255, 0.55);
    /*height: 14.75rem;*/
    padding-left: 3%;
}

.tabs::before,
.tabs::after {
    content: "";
    display: table;
}

.tabs::after {
    clear: both;
}

.tab {
    float: left;
}

.tab-switch {
    display: none;
}

.tab-label {
    position: relative;
    display: block;
    line-height: 2.75em;
    height: 3em;
    padding: 0 1.618em;
    background: rgba(153, 153, 153, 0.25);
    border-right: 0.125rem solid rgba(255, 255, 255, 1);
    color: rgba(55, 55, 55, 0.99);
    cursor: pointer;
    top: 0;
    transition: all 0.25s;
    font-size: 1.1em;
    /*border-top-left-radius: 5px;*/
    /*border-top-right-radius: 5px;*/
}

.tab-label:hover {
    top: -0.45rem;
    transition: top 0.25s;
}

.tab-content {
    /*height: 12rem;*/
    position: absolute;
    z-index: 1;
    top: 2.75em;
    left: 2%;
    /*padding: 1.618rem;*/
    width: 98%;
    background: #fff;
    color: #2c3e50;
    border-bottom: 0.25rem solid #bdc3c7;
    opacity: 0;
    transition: all 0.35s;
}

.tab-switch:checked + .tab-label {
    background: rgba(53, 53, 53, 0.80);
    color: #ffffff;
    border-bottom: 0;
    transition: all 0.35s;
    z-index: 1;
    font-weight: 600;
    top: -0.375rem;
    font-size: 1.1em;
}

.tab-switch:checked + label + .tab-content {
    z-index: 2;
    opacity: 1;
    transition: all 0.35s;
}

.tab-badge {
    display: none;
    position: absolute;
    height: 24px;
    width: 24px;
    color: rgba(255,255,255, 0.95);
    border-radius: 25px;
    background: rgba(255, 150, 45, 1);
    left: -9px;
    top: -7px;
    /* font-size: 2em; */
    /* padding-left: 0%; */
    text-align: center;
    line-height: 26px;
    font-weight: 900;
}

.new-order-alert {
    font-weight: 700;
    color: tomato;
    font-size: 1.3em;
}



.button-container {
    z-index: 800;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: calc(50px + env(safe-area-inset-bottom));

}

.button-container .hide {
    display: none;
}

.button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 11.5rem;
    margin-left: 0;
    /*padding-top: 1.3rem;*/
    /*padding-bottom: 1.3rem;*/
    /*padding-left: 5px;*/
    /*padding-right: 5px;*/
    /*background-color: #3498db;*/
    /*background-color: rgba(55, 55, 73, 0.95);*/
    border: none;
    border-radius: 3px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
    color: white;
    font-weight: 400;
    text-transform: uppercase;
    overflow: hidden;
    font-size: 18px;
    letter-spacing: 1px;
}

.button:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    /*background-color: #54d98c;*/
    background-color: rgba(55, 55, 73, 0.75);
}

.button:hover {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    transform-origin: center center;
}

.button span {
    position: absolute;
    line-height: 0;
}

.button span i {
    transform-origin: center center;
}

.button span:nth-of-type(1) {
    top: 50%;
    transform: translateY(-50%);
    display: flex;
}

.button span:nth-of-type(2) {
    top: 100%;
    transform: translateY(0%);
    font-size: 18px;
}

.button span:nth-of-type(3) {
    display: none;
}

.button span:nth-of-type(4) {
    display: none;
}

.button i {
    margin-right: 10px;
}

.button-container .active {
    /*background-color: #2ecc71;*/
    background-color: rgba(55, 55, 73, 0.5);
}

.button-container .active:before {
    width: 100%;
    transition: width 3s linear;
}

.button-container .active span:nth-of-type(1) {
    top: -100%;
    transform: translateY(-50%);
}

.button-container .active span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
}

.button-container .active span:nth-of-type(2) i {
    animation: loading 500ms linear infinite;
}

.button-container .active span:nth-of-type(3) {
    display: none;
}

.button-container .finished {
    background-color: #54d98c;
}

.button-container .finished .initial {
    display: none;
}

.button-container .finished .loading {
    display: none;
}

.button-container .finished .check {
    visibility: visible;
    display: block !important;
    font-size: 24px;
    animation: scale 0.5s linear;
}

.button-container .finished .check i {
    transform-origin: center center;
}

.button-container .error {
    background-color: red;
}

.button-container .error .initial {
    display: none;
}

.button-container .error .loading {
    display: none;
}

.button-container .error .times {
    display: block !important;
    font-size: 24px;
    animation: scale 0.5s linear;
}

.button-container .error .times i {
    transform-origin: center center;
}

@keyframes loading {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes scale {
    0% {
        transform: scale(10);
    }
    50% {
        transform: scale(0.2);
    }
    70% {
        transform: scale(1.2);
    }
    90% {
        transform: scale(0.7);
    }
    100% {
        transform: scale(1);
    }
}

.input-box .button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: auto;
    margin-left: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    /*background-color: #3498db;*/
    /*background-color: rgba(55, 55, 73, 0.95);*/
    border: none;
    border-radius: 3px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
    color: white;
    font-weight: 400;
    text-transform: uppercase;
    overflow: hidden;
    font-size: 18px;
    letter-spacing: 1px;
}